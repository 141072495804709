import { getOriginUrl } from './queryParamsService'

const isUrlValid = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export const isLocaleAvailable = () => {
  if (!isUrlValid(getOriginUrl())) {
    console.warn('Could not parse url', { url: getOriginUrl() })
    return false
  }

  return true
}
