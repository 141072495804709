import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { IdentityProvider } from '@vp/ubik-context'
import { ContactPreferencesFragmentProps, Fragment } from '../components/Fragment'
import { createAuthConfig } from '../common/getAuthConfig'
import { LocalizationsProvider } from '../context/useLocalizations'

export const mount: MountWithRootOptions<ContactPreferencesFragmentProps> = async (rootElement, renderProps, rootOptions) => {
  const { locale, localizations } = renderProps

  const component = (
    <IdentityProvider auth={createAuthConfig(locale)}>
      <LocalizationsProvider localizations={localizations}>
        <Fragment {...renderProps} />
      </LocalizationsProvider>
    </IdentityProvider>
  )

  return clientMount(component, rootElement, rootOptions)
}
