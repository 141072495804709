import { AlertBox, Box, GridContainer, Row, SwanStyleProps } from '@vp/swan'
import { getIsExplicitOptOutRequired, getParam } from '../services/queryParamsService'
import urlUtils from '../services/urlUtils'
import { ContactPreferencesPageContent } from './ContactPreferences/ContactPreferencesPageContent'
import { EmailPreference } from '../model/email'
import { useLocalization } from '../context/useLocalizations'
import { trackOptionSelected } from '../services/trackingUtils'

const DELAY_BEFORE_REDIRECT_IN_MS = 700

function App (props: SwanStyleProps & { className?: string }) {
  const { t } = useLocalization()
  const error = !getParam('state')

  const onSave = async (emailPreference: EmailPreference) => {
    trackOptionSelected(emailPreference)
    setTimeout(() => {
      urlUtils.redirect(emailPreference)
    }, DELAY_BEFORE_REDIRECT_IN_MS)
  }

  const isExplicitOptOutRequired = getIsExplicitOptOutRequired()

  return (
    <Box {...props}>
      {error && (
        <AlertBox skin='error' mt='7'>
          {t('alert')}
        </AlertBox>
      )}
      {!error && (
        <GridContainer mx={{ xs: '5', sm: 0 }}>
          <Row style={{ height: '100%' }}>
            <ContactPreferencesPageContent onSave={onSave} isExplicitOptOutRequired={isExplicitOptOutRequired} />
          </Row>
        </GridContainer>
      )}
    </Box>
  )
}

export default App
