import { Buffer } from 'buffer'

const getParam = (name: string) => {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(name)
  }
  return null
}

const getParamDecoded = (name: string) => {
  const encodedParam = getParam(name)

  if (!encodedParam) return {}

  return JSON.parse(Buffer.from(decodeURIComponent(encodedParam), 'base64').toString())
}

const getEmail = () => getParamDecoded('data')?.email
const getFirstName = () => getParamDecoded('data')?.firstName
const getLastName = () => getParamDecoded('data')?.lastName
const getOriginUrl = () => getParamDecoded('data')?.originUrl
const getIsExplicitOptOutRequired = () => getParamDecoded('data')?.isExplicitOptOutRequired

export { getParam, getEmail, getFirstName, getLastName, getOriginUrl, getIsExplicitOptOutRequired }
