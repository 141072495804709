import { EmailPreference } from '../model/email'
import { getParam } from '../services/queryParamsService'

const redirect = (emailPreference?: EmailPreference) => {
  try {
    const redirectUrl = new URL(getParam('redirect_uri') || '')

    redirectUrl.searchParams.set('email_preference', emailPreference || 'opt-out')
    redirectUrl.searchParams.set('state', getParam('state') || '')
    redirectUrl.searchParams.set('wauth', getParam('wauth') || '')
    window.location.href = redirectUrl.toString()
  } catch (error) {
    console.error(`Error redirecting to ${window?.location}`, error)
  }
}

const getQueryStringParameter = (name: string) => {
  const params = new URL(window.document.location as any).searchParams
  return params.get(name)
}

const redirector = { redirect, getQueryStringParameter }

export default redirector
