import { VPAuthConfig } from '@vp/ubik-context'

export const createAuthConfig = (culture: string): VPAuthConfig => ({
  culture,
  // TODO: Check how can we do this with Ubbik context maybe?
  developmentMode: process.env.IS_LOCAL === 'true',
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
})
