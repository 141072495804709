import { SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { RenderSwitch } from './RenderSwitch'

export type ContactPreferencesFragmentProps = {
  locale: string,
  localizations: { [key: string]: any }
}

export function Fragment (props: ContactPreferencesFragmentProps) {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.spinner
  ])
  return <RenderSwitch />
}
