import { FlexBox, SelectionSet, SelectionSetInput, SelectionSetLabel, Typography } from '@vp/swan'
import { useLocalization } from '../../context/useLocalizations'
import { EmailPreference } from '../../model/email'

type EmailPreferencesSwitchProps = {
  preference: EmailPreference;
  onPreferencesChanged: (emailPreferences: EmailPreference) => void;
  notBoldOptDown?: boolean;
}

const EmailPreferencesSwitch = (props: EmailPreferencesSwitchProps) => {
  const { preference, onPreferencesChanged } = props
  const { t } = useLocalization()

  const isOptDownVisible = preference === 'opt-down' || preference === 'opt-out'

  const isPreferenceInitialized = () => preference !== 'uninitialized'

  const optInCheckbox = (
    <SelectionSetInput
      onChange={() => onPreferencesChanged('opt-in')}
      id='optInCheckbox'
      data-testid='optInCheckbox'
      aria-labelledby='optInCheckboxLabel'
      disabled={!isPreferenceInitialized()}
      value='opt-in'
    >
      <SelectionSetLabel id='optInCheckboxLabel'>{t('contactPreferences.optInCheckBoxMessage')}</SelectionSetLabel>
    </SelectionSetInput>
  )

  const optOutCheckbox = (
    <SelectionSetInput
      onChange={() => onPreferencesChanged('opt-out')}
      id='optOutCheckbox'
      data-testid='optOutCheckbox'
      aria-labelledby='optOutCheckboxLabel'
      disabled={!isPreferenceInitialized()}
      value='opt-out'
    >
      <SelectionSetLabel id='optOutCheckboxLabel'>{t('contactPreferences.optOutCheckBoxMessage')}</SelectionSetLabel>
    </SelectionSetInput>
  )

  const optDownCheckbox = (
    <FlexBox flexDirection='column' style={{ width: '100%' }}>
      <Typography
        fontSkin={props.notBoldOptDown ? 'body-standard' : 'body-standard-bold'}
        mb='between-actions'
        mt='between-subsections'
      >
        {t('contactPreferences.optDownTitle')}
      </Typography>
      <FlexBox flexDirection='row'>
        <SelectionSetInput
          onChange={() => onPreferencesChanged('opt-down')}
          id='optDownCheckbox'
          data-testid='optDownCheckbox'
          aria-labelledby='optDownCheckboxLabel'
          disabled={!isPreferenceInitialized()}
          value='opt-down'
        >
          <SelectionSetLabel id='optDownCheckboxLabel'>
            {t('contactPreferences.optDownCheckBoxMessage')}
          </SelectionSetLabel>
        </SelectionSetInput>
      </FlexBox>
    </FlexBox>
  )

  return (
    <SelectionSet skin='simple-column' variant='single-select' selectedValue={preference}>
      {optInCheckbox}
      {optOutCheckbox}
      {isOptDownVisible && optDownCheckbox}
    </SelectionSet>
  )
}

export default EmailPreferencesSwitch
export type { EmailPreference }
