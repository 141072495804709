import { Button, Column, GridContainer, Row, SelectionSet, SelectionSetInput, SelectionSetLabel, Typography } from '@vp/swan'
import { useState } from 'react'
import { EmailPreference } from '../../model/email'
import { useLocalization } from '../../context/useLocalizations'

type ContactPreferencesSignUpWithExplicitOptOutProps = {
  onSave: (emailPreferences: EmailPreference) => void;
}

export const ContactPreferencesSignUpWithExplicitOptOut = ({ onSave }: ContactPreferencesSignUpWithExplicitOptOutProps) => {
  const [preference, setPreference] = useState<EmailPreference>('opt-in')
  const { t } = useLocalization()

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreference(event.target.checked ? 'opt-out' : 'opt-in')
  }

  return (
    <GridContainer>
      <Row>
        <Column span={12} mb='to-actions'>
          <Typography>{t('contactPreferences.setupMessageExplicitOptOut')}</Typography>
        </Column>
      </Row>
      <Row>
        <Column span={12} mb='between-actions'>
          <Button skin='primary' width='full-width' onClick={() => onSave(preference)}>
            {t('contactPreferences.nextStep')}
          </Button>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <SelectionSet mb='between-actions' skin='simple-column' variant='multi-select'>
            <SelectionSetInput value='email-preference' onChange={handleOnChange}>
              <SelectionSetLabel>
                {t('contactPreferences.optOutSignUpCheckBoxMessage')}
              </SelectionSetLabel>
            </SelectionSetInput>
          </SelectionSet>
        </Column>
      </Row>
    </GridContainer>
  )
}
