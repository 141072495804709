import { Column, H1, Typography } from '@vp/swan'
import { getEmail, getFirstName } from '../../services/queryParamsService'
import { EmailPreference } from '../../model/email'
import { useLocalization } from '../../context/useLocalizations'
import { ContactPreferencesSignUpWithExplicitOptOut } from './ContactPreferencesSignUpWithExplicitOptOut'
import { ContactPreferencesSignUp } from './ContactPreferencesSignUp'

type ContactPreferencesPageContentProps = {
  onSave: (emailPreferences: EmailPreference) => void;
  isExplicitOptOutRequired?: boolean;
}

export const ContactPreferencesPageContent = ({
  onSave,
  isExplicitOptOutRequired = false,
}: ContactPreferencesPageContentProps) => {
  if (isExplicitOptOutRequired) {
    return (
      <Column span={4} spanSm={8} spanMd={6} spanXs={12} offset={4} offsetSm={2} offsetMd={3} verticallyCenterContent>
        <WelcomeMessage />
        <ContactPreferencesSignUpWithExplicitOptOut onSave={onSave} />
      </Column>
    )
  }

  return (
    <Column span={4} spanSm={8} spanMd={6} spanXs={12} offset={4} offsetSm={2} offsetMd={3} verticallyCenterContent>
      <WelcomeMessage />
      <SetupMessage />
      <ContactPreferencesSignUp onSave={onSave} />
    </Column>
  )
}

const WelcomeMessage = () => {
  const { t } = useLocalization()
  const firstName = getFirstName()
  return (
    <H1 fontSize='x2large'>
      {t('welcomeMessage', { firstName })}
    </H1>
  )
}

const SetupMessage = () => {
  const { t } = useLocalization()
  const email = getEmail()
  return (
    <Typography fontSize='standard' mb='between-actions'>
      {t('setupMessage', { email })}
    </Typography>
  )
}
