import { useState } from 'react'
import { Box, Button, Column, GridContainer, Row, useId } from '@vp/swan'
import { useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../context/useLocalizations'
import { EmailPreference } from '../../model/email'
import ContactPreferencesDescription from './ContactPreferencesDescription'
import EmailPreferencesSwitch from './EmailPreferencesSwitch'

type ContactPreferencesSignUpProps = {
  onSave: (emailPreferences: EmailPreference) => void;
}

export const ContactPreferencesSignUp = ({ onSave }: ContactPreferencesSignUpProps) => {
  const { tenant, locale: culture } = useUserContext()
  const [preference, setPreference] = useState<EmailPreference>('none')
  const { t } = useLocalization()
  const id = useId()

  return (
    <GridContainer>
      <Row>
        <Column span={12} role='group' aria-labelledby={id}>
          <Box mb='to-actions' id={id}>
            <ContactPreferencesDescription tenant={tenant || ''} locale={culture} />
          </Box>
          <EmailPreferencesSwitch
            onPreferencesChanged={(preference: EmailPreference) => {
              setPreference(preference)
            }}
            preference={preference}
            notBoldOptDown
          />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Button
            skin='primary'
            width='full-width'
            data-testid='updateButton'
            marginY='3'
            disabled={preference === 'none'}
            onClick={() => onSave(preference)}
          >
            {t('contactPreferences.nextStep')}
          </Button>
        </Column>
      </Row>
    </GridContainer>
  )
}
