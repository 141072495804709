// import React, { useEffect } from 'react'
import { useEffect } from 'react'
import { useStyles } from '@vp/ubik-context'
import { isLocaleAvailable } from '../services/localeFilterService'
import urlUtils from '../services/urlUtils'
import styles, { stylesheet } from './RenderSwitch.css'
import React from 'react'
import { FlexBox, Divider, Spinner } from '@vp/swan'
import { useLocalization } from '../context/useLocalizations'
import { VistaHeader } from '../images/VistaHeader'
import App from './App'

export function RenderSwitch () {
  const [isPageVisible, setIsPageVisible] = React.useState(false)
  const { t } = useLocalization()
  useStyles(stylesheet)

  useEffect(() => {
    const shouldComponentRender = isLocaleAvailable()
    setIsPageVisible(shouldComponentRender)

    if (!shouldComponentRender) {
      urlUtils.redirect()
    }
  }, [])

  return isPageVisible
    ? (
      <FlexBox flexDirection='column' className={styles.appWrapper}>
        <FlexBox justifyContent='center' pt='5' pb='4' px='5'>
          <VistaHeader />
        </FlexBox>
        <Divider />
        <App className={styles.appContent} />
      </FlexBox>
      )
    : (
      <FlexBox justifyContent='center' mt='12'>
        <Spinner accessibleText={t('loadingText')} />
      </FlexBox>
      )
}
