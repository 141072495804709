import { getTracking } from '@vp/tracking'
import { EmailPreference } from '../model/email'

export const trackOptionSelected = (option: EmailPreference) => {
  try {
    const tracking = getTracking()

    if (!tracking) {
      console.info('Tracking is not available')
      return
    }

    tracking.track(
      'User Opt-In Option Selected',
      {
        category: 'Login',
        label: option,
      },
      {}
    )
  } catch {}
}
