import React, { ReactNode } from 'react'

interface LocalizationProviderProps {
  children: ReactNode
  localizations: { [key: string]: any }
}

export interface UseLocalizationsValue {
  t: (key: string, tokens?: { [key: string]: string }) => string
}

export const localizationContext = React.createContext<UseLocalizationsValue>({
  t: (key: string) => {
    return key
  }
})

export const LocalizationsProvider: React.FC<LocalizationProviderProps> = ({ children, localizations }) => {
  const useLocalizationsValue: UseLocalizationsValue = {
    t: (key, tokens = {}) => {
      let localizedText = getNestedLocalization(localizations, key)
      if (!localizedText) {
        console.error(`Localization for key ${key} not found`)
        return key
      }

      localizedText = localizedText.replace(/{{(.*?)}}/g, (_: any, token: string) => tokens[token] || `{{${token}}}`)

      return localizedText
    }
  }
  return <localizationContext.Provider value={useLocalizationsValue}>{children}</localizationContext.Provider>
}

export const useLocalization = (): UseLocalizationsValue => {
  return React.useContext(localizationContext)
}

const getNestedLocalization = (localizations : { [key : string]: any }, path : string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], localizations) as any as string
}
